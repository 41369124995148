:root {
  --white: white;
  --black: black;
  --light: #f8f9fa;
  --dark: #141418;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --blue: #007bff;
  --blue2: #2575fc;
  --twitter-blue: #007bff;
  --indigo: #6610f2;
  --paper-color: #e0c9a6;
  --stripeColor: #32325d;
  --dark-primary: #0e2439;
  --border-line: #343a40;  

  --bootstrap-dark: #212529;

  --bg-primary: #23232e;
  
  /* Primary (#3B71CA)
  Secondary (#9FA6B2)
  Success (#14A44D)
  Danger (#DC4C64)
  Warning (#E4A11B)
  Info (#54B4D3)
  Light (#FBFBFB)
  Dark (#332D2D) */

  /* overlay colour */    
  --overlay-bg-color-light: rgba(0,0,0,0.5); 
  --overlay-bg-color-dark: rgba(0,0,0,0.9);
  --overlay-bg-color-fallback: rgb(0, 0, 0); 
  --color-theme-7-3378-custom: #7900ff;
  --coolBgFBColor4: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
  --color-theme-3-999: #93ffd8;
  --bg-secondary: #141418;

  --font-family-primary: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;

  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
}

/* Default React CSS ==================================================================================================================================================================================================== */

/* made it worst as everything titled towards one side
* {
  box-sizing: border-box;  
} */

/* cannot just use fixed postion to stop elements from moving around */
/* .layoutwrap1 {
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS ==================================================================================================================================================================================================== */

.buttons {
  display: flex;
  justify-content: center;
  width: 10rem;
  height: 2.7rem;    
  /* background-color: var(--color-theme-7-3378-custom); */
  /* background-color: var(--blue2);   */
  background-color: var(--gray-dark);
  color: var(--light);
  border-radius: 2px;
  /* padding: 0.2rem 0.5rem; */
  padding: 0.7rem;
  text-align: center;
  font-size: small;
  font-weight: bold;
  cursor: pointer;
}

.buttons li:hover,
.buttons li:focus,
.buttons li:active {
  color: #fff;
  background-image: var(--googleBgColor5);
}

/* position items to top right ============================================================================================================================================================================ */

/* mobile */
.topright {
  position: absolute;
  top: 8px;
  right: 22px;  
  font-size: 2rem;  
  cursor: pointer;
}
/* desktop */
@media only screen and (min-width: 600px) {
  .topright {    
    position: absolute;
    top: 3rem;
    right: 10rem;
    font-size: 3rem;
    color: #fff;  
  }
}
.topright:hover {  
  background-color: var(--coolBgFBColor2);
  color: var(--coolBgFBColor1);    
}

/* top navbbar business icon cicular ============================================================================================================================================================================ */

.business-icon-img {
  border-radius: 50%;
  object-fit: cover;
}

/* top navbbar ============================================================================================================================================================================ */

.mobile-auth-navbar {
  display: flex;
  justify-content: space-evenly;
  background-color: var(--light);
  border-bottom: 2px solid var(--gray);
  list-style: none;  
  height: 4rem;    
  /* the folliwng css properties are key to makin a navbar that sticks to the top */
  width: 100%;
  margin-top: 0;
  position: fixed;
  top: 0;
  z-index: 100;
}

.mobile-auth-navbar li {
  flex: 0 0 10%;
  padding: 1rem;
}

.mobile-auth-navbar li:hover {
  cursor: pointer;
}

/* use this if first icon on navabr is a circle icon ============================================================================================================================================================================ */
/* .mobile-auth-navbar li:nth-child(1) {
  flex: 0 0 10%;
  padding: 0.1rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
} */
/* ============================================================================================================================================================================ */

.mobile-auth-navbar li span i {
  color: var(--gray-dark);
  font-size: 1.5rem;
}

/* jumbotron on loanding ============================================================================================================================================================================ */

.jumbo-section {
  /* position: fixed; */
  width: 100%;
  background: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  /* url('./mathpic2.jpg') 0% 0% / cover no-repeat; */
  /* background: url('/webpic3.jpg') 20px 20px/ 150px 218px no-repeat,
    url('/webpic2.jpg') 0% 0% / cover; */
}

/* about us on landing ============================================================================================================================================================================ */

.about-us {
  font-family: var(--font-family-primary);
  font-size: 20px;
  line-height: 1.5;  
  padding: 2em 1em;   
  margin: 0 auto;
  /* margin-top: 3rem; */
  max-width: 50em; 
  word-wrap: break-word;     
  text-align: justify;
}

/* full page loading cover ============================================================================================================================================================================ */
/* create a sliding up effect that covers the entire view port for mobile and desktop */

.fullpage-loader {  
  position: fixed;  
  width: 100%;
  padding: 0;
  bottom: 0;
  margin-bottom: 0;
  background-color: var(--light);  
  display: flex; 
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;      
  /* set the height to zero and display to none */  
  /* display: none;  */
  /* display: hidden;  */  
  /* we set zindex instead of changing display */    
  height: 0%;  
  /* 99999 need this to be avive react bootstrap */
  z-index: 99999;
  margin-top: 0;
  /* set transition to control the animation speed */  
  transition: all 0.5s ease;
}

/* full page loading cover ============================================================================================================================================================================ */
/* to create the slide up effect we need expand bottom nav */
.expand-bottom-nav {  
  height: 100%;  
  display: flex; 
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;      
  /* display: flex; 
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;       */
}

/* dahsboard search bar ============================================================================================================================================================================ */
.search-bar {
  width: 80%;  
  /* margin-bottom: 3rem; */
}

@media only screen and (min-width: 600px) {
  .search-bar {
    width: 30rem;
    /* margin-bottom: 5rem; */
  }
}

/* dahsboard company-card ============================================================================================================================================================================ */
.company-card-wrapper {
  display: flex;  
  flex-wrap: wrap;  
}

.company-card {
  width: 90%;    
  margin: 0 auto;
  margin-top: 3rem;
}

@media only screen and (min-width: 600px) {
  .company-card {    
    margin: 0 auto;
    width: 50%;    
    margin-top: 3rem;
  }
  .company-card-wrapper {
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-flow: column-reverse;
  }  
}

/* overlay backdrop for modals ============================================================================================================================================================================ */

#overlay {  
  position: fixed;  
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;    
  background-color: var(--overlay-bg-color-light);
  z-index: 200;  
  overflow: scroll;
  /* added: overflow-y and overflow-x to see if stop elements from moving around*/
  /* overflow-y: scroll;
  overflow-x: hidden; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#overlay::-webkit-scrollbar {
display: none;
}

#overlay::-webkit-scrollbar-track {
display: none;
}

#overlay::-webkit-scrollbar-track {
display: none;
}

#overlay::-webkit-scrollbar {
display: none;
}

#overlayclick {  
  position: fixed;  
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;    
  z-index: 0;  
  cursor: pointer;      
}

/* highlight user selections ============================================================================================================================================================================ */

.highlight {
  background-color: none;   
}

.highlight:hover {
  color: var(--light); 
  background-color: var(--cyan); 
  cursor: pointer;
}

/* tagit ============================================================================================================================================================================ */

.tagit {
  font-size: 0.75rem;
  padding: 0.1rem 0.5rem;  
  border-radius: 0.25rem;  
  margin-bottom: 0.75rem;
  margin-left: 0.5rem;
  display: inline-block;  
  height: 20px;
  width: fit-content;
  /* background-color: var(--color-theme-3-999);  
  color: var(--bg-secondary); */
  background-color: teal;  
  color: var(--white);  
}

.tagit:hover {
  background-color: var(--gray);  
  color: var(--white);  
}

/* stripe button ============================================================================================================================================================================ */

.stripe-btn {
  width: 184px;
  height: 42px;
  background-color: var(--stripeColor);
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  margin-top: 2rem;
  background: #32325d;
}
.stripe-btn > .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.stripe-btn > .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.stripe-btn > .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: 'Roboto';
}
@media only screen and (orientation: portrait) and (max-width: 730px) {
  .stripe-btn > .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.2px;
    font-family: 'Roboto';
  }
}
.stripe-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.stripe-btn:active {
  background: #1669f2;
}

/* dashboard wrapper to stop it from moving around ============================================================================================================================================================================ */

.dashboard-wrapper {
  /* top: 0;
  bottom: 0;
  position: fixed; */
  /* overflow-y: scroll;
  overflow-x: hidden; */
  /* overflow: scroll; */
}  

/* job search results ============================================================================================================================================================================ */

.joblist {  
  width: auto;
}
.joblist p {    
  min-height: 2rem;  
  padding: 8px;
  font-weight: bold;
}
/* .joblist p:hover {    
  min-height: 8rem;
  background-color: var(--body-text-primary);
  color: var(--light);
} */

/* job search results ============================================================================================================================================================================ */

#crologo-desktop {
  display: none;
}

@media only screen and (min-width: 600px) {
  #crologo-desktop {
    display: block;
  }
}

#crologo-mobile {  
  display: block;
}

@media only screen and (min-width: 600px) {
  #crologo-mobile {
    display: none;
  }
}

.topic-tiles {
  flex: 1 0 auto;
  cursor: pointer;
  transition: all 600ms ease;  
  background-color: #212529;
  color: #fff;
  
  /* color: #23232e;
  margin: 2px;   */

  /* it is difficult to use image just use color and text to modify the tiles to reflect programming language */
  /* background: url('/jslogo.png') 0% 0% / cover; */
  /* background: url('/jslogo.png') 20px 20px/ 150px 218px no-repeat;   */
  /* color: #212529; */
  /* works */
  /* background: url('./crolanding3mobile.jpeg') 20px 20px/ 150px 218px no-repeat, */
  /* background: url('./crolanding3mobile.jpeg') 20px 20px/ 150px 50px no-repeat; */
  /* url('./crolanding3mobile.jpeg') 0% 0% / cover; */
  /* background-color: #23232e;
  color: #fff;   */
}

.change-to-list-form {
  flex: 0 0 100%;  
  height: fit-content;
  margin: 0.5rem;
}

@media only screen and (min-width: 600px) {
  .change-to-list-form {
    flex: 0 0 50%;
    margin: 0.5rem;
  }
}

.change-to-small-grid {
  flex: 0 0 5px;
}

.topic-tiles:hover {
  box-shadow: 15px 20px 10px 0 var(--bg-primary);
  transform: translateY(-20px) translateX(-20px) scale(1.2);
  z-index: 1;
  /* background-color: mediumspringgreen; */
  /* position: relative; */
}

/* https://stackoverflow.com/questions/67891742/how-to-infinitely-scroll-horizontal-text-with-javascript */

/* .nonstopscrollsection {
  overflow :hidden;
  width: 150px;
  display:inline-block;
  border: 1px solid red;
  height: 50px;
  white-space: nowrap;
} */

/* .nonstopscrollsection > div {
  width: 566px;
  display: flex;
  flex-wrap: nowrap;
  animation: move 2s infinite linear; /* set the time to what you want of course */

/* @keyframes move {
  to {
    transform: translateX(-50%);
  }
} */
/* .nonstopscrollsection > h1 {
  font-size: 20px;
  margin:0;
  padding-left:0px;
  border: 1px solid blue;
  width:283px;
} */

/* #navbar-text {  
  animation: move 10s infinite linear; 
}

@keyframes move {
  to {
    transform: translateX(-50%);
  }
} */

#above-posh-ui {
  display: flex;
  position: fixed;
  right: 5%;
  top: 85%;
  height: 4rem;
  width: 40rem;
  /* border: 2px solid #00c2e0; */
  /* background-color: #0e2439; */  
  border: 2px solid #0e2439;
  background-color: #00c2e0;
  color: #fff;
  padding: 1rem;
  z-index: 10000;
}